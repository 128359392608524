import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { graphql } from 'gatsby'
import DataViewer from '~utils/DataViewer'
import PageHeader from '~components/PageHeader'
import Section from '~components/Section'
import RichText from '~components/RichText'
import useSiteSettings from '~utils/useSiteSettings'
import Image from '~components/Image'
import Button from '~components/Button'

const Program = ({ data }) => {

	const { page } = data
	const { registerTitle, registerText, registerButton, registerImage} = useSiteSettings()
	
	return (
		<Wrap>
			<Global styles={css`
				body{
						background-color: var(--white);
					}
				`}
			/>
			<PageHeader title={page?.title} time={page.time} text={page.introText} image={page.headerImage} reverse/>
			<Content>
				<Container>
					<RichText content={page.content}/>
				</Container>
			</Content>
			<RegSection>
				<Register>
					<RegisterTitle>{registerTitle}</RegisterTitle>
					<RegisterText><RichText content={registerText}/></RegisterText>
					<RegisterButton color={registerButton.color} link={registerButton.link}>{registerButton.text}</RegisterButton>
					<RegisterImage image={registerImage}/>
				</Register>
			</RegSection>
			<DataViewer data={page} name='page'/>
		</Wrap>
	)
}

const Wrap = styled.div`	
	
`
const Content = styled(Section)`
	
`
const Container = styled.div`
	grid-column: span 12;
	max-width: 661px;
	margin: 0 auto;
	> div{
		margin-bottom: var(--xl);
	}
`
const RegSection = styled(Section)`
	background-color: var(--lightBlue);
`
const Register = styled.div`
	padding: var(--xl) 0 var(--m);
	grid-column: span 12;
	max-width: 661px;
	margin: 0 auto;
	text-align: center;
`
const RegisterTitle = styled.h3`
	margin-bottom: var(--m);
`
const RegisterText = styled.div`
	margin-bottom: var(--l);
`
const RegisterButton = styled(Button)`
	margin-bottom: var(--l);
`
const RegisterImage = styled(Image)`
	max-width: 396px;
	margin: 0 auto;
	padding: 0 var(--xl);
`

Program.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
}

export const query = graphql`
  query ProgramQuery($slug: String) {
    page: sanityProgram(slug: {current: {eq: $slug}}) {
			seo{
				...seo
			}
			title
			type
			time
			introText: _rawIntroText(resolveReferences: {maxDepth: 5})
			headerImage {
				...imageWithAlt
			}
			content: _rawContent(resolveReferences: {maxDepth: 10})
    }
  }
`

export default Program